@font-face {
    font-family: 'Gilroy-Bold';
    font-style: normal;
    font-weight: normal;
    src: url('fonts/Gilroy-Bold.eot');
    src: local('Gilroy Bold'), local('Gilroy-Bold'),
    url('fonts/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
    url('fonts/Gilroy-Bold.woff') format('woff'),
    url('fonts/Gilroy-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Gilroy-Light';
    font-style: normal;
    font-weight: normal;
    src: url('fonts/Gilroy-light.eot');
    src: local('Gilroy light'), local('Gilroy-light'),
    url('fonts/Gilroy-light.eot?#iefix') format('embedded-opentype'),
    url('fonts/Gilroy-light.woff') format('woff'),
    url('fonts/Gilroy-light.ttf') format('truetype');
}

@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: normal;
    src: url('fonts/Gilroy-Regular.eot');
    src: local('Gilroy'), local('Gilroy'),
    url('fonts/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
    url('fonts/Gilroy-Regular.woff') format('woff'),
    url('fonts/Gilroy-Regular.ttf') format('truetype');
}